$(document).on('click', '.show-more-orders', function(e) {
	e.preventDefault();
	$(this).closest('table').find('.hidden').removeClass('hidden');
	$(this).remove();
});
$(document).on('click', '.order-table tbody tr', function(e){
	window.location.href='/account_history_info.php?order_id=' + $(this).attr('orders_id') + '&orderType=' + $(this).data('type');
});

// <script language="javascript"><!--
function rowOverEffect(object) {
  if (object.className == 'moduleRow') object.className = 'moduleRowOver';
}

function rowOutEffect(object) {
  if (object.className == 'moduleRowOver') object.className = 'moduleRow';
}

function checkBox(object) {
  document.account_notifications.elements[object].checked = !document.account_notifications.elements[object].checked;
}

$(document).on('change', '#form_newsletter_general, #form_manufacturers_notification', function() {
	$(this).submit();
	if($('#form_newsletter_general input[name="newsletter_general"]').prop('checked') === true ){
		var create_source = 'user_account';
		if( window['yaCounter' + window.PROSKTR.yaCounterId] ) window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal(create_source);
		if(window.ga) ga('send', 'event', 'email', 'subcribe', create_source, {'nonInteraction': 1});
	}

});

$(document).on('click', '.open_popup_instruction', function() {
	$('#instructionShooseChoose-popup').fadeIn('slow');
	if($('#sizes-description .popup').length > 0){
		$('#instructionShooseChoose-popup').css({'zIndex':'1000','top':($(window).height()*0.20 + ($(window).scrollTop()-0))+'px'});
		$('#instructionShooseChoose-popup .popup').css({'left':$('#sizes-description .popup').css('left').slice(0,-2) - 5 + 'px','border' : '#756A6A solid 5px'});		
	} else {
		$('#instructionShooseChoose-popup').css({'top':'0px'});	
	}
});
//--></script>

$('#account_payment').on('change', 'input[name=payment_type]', function() {
	$('#account_payment [name=summa]').data('required', this.value == '2' );
});
// if($('[name=summa]').length){
// 	$('[name=summa]').val($('[name=order_id] option:selected').data('sum'));
// }
$('#account_payment [name=order_id]').change(function(){
	var form = $('#account_payment');
	var selected_option = $('[name=order_id] option:selected', form);
	var sum = selected_option.data('sum');
	var sumDisc = selected_option.data('sum_disc');
	var orders_id = $(this).val();
	$('.order_slider').removeClass('active');
	$('div[name='+orders_id+']', '.reviews-ordered').addClass('active');
	$('.prepay-disc-desc', form)[sum == sumDisc ? 'hide' : 'show' ]();
	$('[name=summa]', form).val('');
	$('#payment-ot-total', form).text(selected_option.data('ot_total_str'));
	$('#payment-prepay', form).text(selected_option.data('prepay'));
	$('#payment-to-prepay', form).text(selected_option.data('to_prepay'));

	if( selected_option.data('payMethod').length ) {
		$('[name=payment][value=' + selected_option.data('payMethod') + ']').prop('checked', true).trigger('change');
	}

	var paymentType = $('[name=payment]:checked').data('type');
	$('#labelOnLinePay').text('');
	$('.full-sum', form).html('<strong style="font-size:14pt">'+selected_option.data('to_prepay')+'</strong>');
	if( paymentType!='OffLine' ) {
		if( selected_option.data('disc_value') > 0 && selected_option.data('val_ship') == 1 ) {
			$('#labelOnLinePay').text('(Скидка ' + selected_option.data('disc_value') + '% при полной оплате и бесплатная доставка):');
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> с учетом -' + selected_option.data('disc_value') + '% и бесплатной доставки');
		} else if( selected_option.data('disc_value') > 0 ) {
			$('#labelOnLinePay').text('(Скидка ' + selected_option.data('disc_value') + '%  при полной оплате):');
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> с учетом -' + selected_option.data('disc_value') + '% ');
		} else if( selected_option.data('val_ship') == 1 ) {
			$('#labelOnLinePay').text('(Бесплатная доставка):');
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> с учетом бесплатной доставки');
		}
	}

}).trigger('change');
$('[name=payment]').change(function(){
	var form = $('#account_payment');
	var selected_option = $('[name=order_id] option:selected');
	var sumDisc = selected_option.data('sum_disc');
	var paymentType = $(this).data('type');
	$('.full-sum', form).html('<strong style="font-size:14pt">'+selected_option.data('to_prepay')+'</strong>');
	if( paymentType!='OffLine' ) {
		if( selected_option.data('disc_value') > 0 && selected_option.data('val_ship') == 1 ) {
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> с учетом -' + selected_option.data('disc_value') + '% и бесплатной доставки');
		} else if( selected_option.data('disc_value') > 0 ) {
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> с учетом -' + selected_option.data('disc_value') + '% ');
		} else if( selected_option.data('val_ship') == 1 ) {
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> с учетом бесплатной доставки');
		}
	}
});
$(".account_payment_button").click(function(e){
	var rad = $('input[name=payment_type]', this);
	rad.prop('checked', true).trigger('change');
	$(".account_payment_button").find('jq-radio').removeClass('checked');
	$(".account_payment_button").removeClass('active');
	$(this).addClass('active');
	$(this).find('jq-radio').addClass('checked');
});
$("#for_example_five_persent").click(function(e){
	var form = $('#account_payment');
	var selected_option = $('[name=order_id] option:selected', form);
	var sum = selected_option.data('sum');
	$('[name=summa]', form).val(Math.ceil(sum * 0.05,10));
});

$('[name="transferProduct[]"], [name^="transferQuantity"]').change(function(){
	if($(this).attr('name').search('transferProduct') + 1){
		if($(this).is(':checked')){
			count = $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val() == undefined ? 1 : $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val();
			$(this).closest('tr').find('[name^="transferQuantity"]').data('lastcount',count);
			$('#cash_return').text($('#cash_return').text()-0 + ($(this).closest('tr').data('price') * count - 0));
		} else {
			count = $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val() == undefined ? 1 : $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val();
			$(this).closest('tr').find('[name^="transferQuantity"]').data('lastcount',count);
			$('#cash_return').text($('#cash_return').text()-0 - ($(this).closest('tr').data('price')* count - 0));
		}
	}
	if($(this).attr('name').search('transferQuantity') + 1 && $(this).closest('tr').find('[name="transferProduct[]"]').is(':checked') ){
		count = $(this).val() - $(this).data('lastcount');
		$('#cash_return').text($('#cash_return').text()-0 + ($(this).closest('tr').data('price') * count));
		$(this).data('lastcount',$(this).val());
	}
});
$('.go_input_data').click(function(){
	if($('[name="transferProduct[]"]:checked').length == 0){
		$('.error_dont_select_prod').show();
		return false;
	}
	$('.error_dont_select_prod').hide();
	$('.input_data').show();
	$(this).closest('.choose_product').hide();
	$('[name="summa"]').val($('#cash_return').text());
});
$('.show_choose_product').click(function(){
	$('[name="transferProduct[]"]:checked').trigger('click');
	$('#cash_return').text('0.00');
	$('.choose_product').show();
	$(this).closest('.input_data').hide();
});