var autocomplete_city_cache = { byCity: {}, byZoneId: {} };
var defaultValidateSetup = {
	description : {
		commonDescription : {
			required : '<div class="error">���� ����������� ��� ����������</div>',
			rus : '<div class="error">����� ������ ���� �� ������� �����</div>',
			name : '<div class="error">�������� ������</div>',
			fullname : '<div class="error">��������� � �������: "��� �������" �������� �������</div>',
			email : '<div class="error">�� ����� ����� e-mail</div>',
			phone_10 : '<div class="error">�� ����� ����� ����� ��������</div>',
			phone_11 : '<div class="error">�� ����� ����� ����� ��������</div>',
			pass : '<div class="error">������ ������ ��������� ������� 5 ��������</div>',
			passConfirm : '<div class="error">���� "������" � "����������� ������:" ������ ���������</div>',
			valid : '<div class="success">��</div>',
			autocompleteCity : '<div class="error">�� ������ �����</div>',
			numeric : '<div class="error">�������� ������</div>',
			dateRu : '<div class="error">�������� ������ ���� (��.��.����)</div>',
		}
	},
	eachInvalidField: function( event, statuses, options ) {
		var dataDescription = $(this).data('description') || 'commonDescription';
		var text = '';
		$.each( statuses, function(i, val) {
			if ( val == false ) {
				if( i == 'conditional' ) {
					text = options.description[ dataDescription ][ statuses.conditionalName ] || options.description[ 'commonDescription' ][ statuses.conditionalName ] || '';
				} else {
					text = options.description[ dataDescription ][ i ] || options.description[ 'commonDescription' ][ i ] || '';
				}
			}
		} );
		fieldDescribedby = $(this).data('describedby');
		$(this).addClass('valid-error');
		if( fieldDescribedby != undefined && $('#'+fieldDescribedby).length ) {
			$('#'+fieldDescribedby).html(text);
		} else {
			$(this).closest('.j-value').find('.validation-description').html(text);
		}
	},
	eachValidField : function() {
		fieldDescribedby = $(this).data('describedby');
		$(this).removeClass('valid-error');
		if( fieldDescribedby != undefined && $('#'+fieldDescribedby).length ) {
			$('#'+fieldDescribedby).html('');
		} else {
			$(this).closest('.j-value').find('.validation-description').html('');
		}
	},
	invalid: function() {
		$(this).addClass('form-valid-error');
		$('.sc-form-error').show();
		setTimeout(function() { $('.sc-form-error').hide(); }, 2000);
	},
	valid: function() {
		$(this).removeClass('form-valid-error');
	},
	conditional: {
		rus: function( value, options ) {
			return value.length == 0 || /^[�-��-߸�\s\-,]+$/.test(value);
		},
		name: function( value, options ) {
			return value.length == 0 || /^[�-��-߸�a-zA-Z\s\-,]+$/.test(value);
		},
		fullname: function( value, options ) {
			return value.length == 0 || /^[�-��-߸�a-zA-Z\s\-,]+ [�-��-߸�a-zA-Z\s\-,]+$/.test(value);
		},
		email: function( value, options ) {
			return value.length == 0 || /[-0-9a-z_]+@[-0-9a-z_^.]+\.[a-z]+/i.test(value);
		},
		phone_11: function( value, options ) {
			value = value.replace(/[^0-9]/g,"");
			return value.length==PROSKTR.country.phoneLength || value.length==0;
		},
		pass: function( value, options ) {
			return value.length>=5;
		},
		autocompleteCity: function( value, options ) {
			var holder = $(this).closest('.city-holder');
			var city = $('input[name=city]', holder);
			var zone_id = $('[name=zone_id]', holder);
			return parseInt(zone_id.val()) > 0 && city.val().length > 0;
		},
		numeric: function( value, options ) {
			return !isNaN( value.trim() );
		},
		ajaxEmail: function( value, options, a ) {
			// email_statuses: 
			// 0 - not_checked / checking / error
			// 1 - checked - email_not_exists
			// 2 - checked - email_exists
			// 3 - logined
			// 4 - process
			var status = $(this).data('validEmailStatus');
			var phoneEl = $(this).closest('form').find('input[name=phone]');
			if (phoneEl.length == 0) {
				phoneEl = $(this).closest('form').find('input[name=telephone]');
			}
			if ( phoneEl.length > 0 && phoneEl.val().length > 0 ) {
				status = 3;
				$(this).data('validEmailStatus', status);
				if( !$(this).closest('form').hasClass('on-change-validate-form') ) {
					return true;
				}
			}
			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( lastVal != $(this).val() ) {
				$(this).data('lastVal', $(this).val());
				status = 0;
			}
			if( status == 1 || status == 3 || customer.isLogin() ) {
				if( !$(this).closest('form').hasClass('on-change-validate-form') ) {
					return true;
				}
			}
			if( $(this).closest('form').hasClass('on-change-validate-form') && this.val() == this.data('old') ) {
				return true;
			}
			var emailEl = this;
			var passEl = $(this).closest('form').find('input[name=password]');
			var passRow = passEl.closest('.j-value');
			var pass_btn = $('.pass-btn', passRow);
			if( !pass_btn.data('login-binded-email') ) {
				pass_btn.data('login-binded-email', true);
				pass_btn.on('click', function() {
					customer.login(
						{
							customerInfo: {
								pass: passEl.val(),
								email: emailEl.val(),
							},
							error: function(msg) {
								if ( phoneEl.length == 0 || phoneEl.val().length == 0 ) {
									emailEl.data('validEmailStatus', 0);
									passRow.find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>').addClass('not-empty');
								}
							},
							success: function(msg) {
								emailEl.data('validEmailStatus', 3);
							}
						}
					);
				});
			}
			customer.checkEmail({
				email: value,
				email_exists: function( msg ) {
					passRow.removeClass('hidden');
					passRow.find('.form-control__placeholder').text('������ � email');
					$(emailEl).data('validEmailStatus', 2);
					var moreText = '';
					if ( emailEl.hasClass('registerMail') ) {
						moreText = ' <a class="js-login greydotted">����� � ���� �������</a>';
					}
					$(emailEl).closest('.j-value').find('.validation-description').html('<div class="error">��� e-mail ��� ���������������' + moreText + '</div>');
				},
				email_not_exists: function( msg ) {
					passRow.addClass('hidden');
					$(emailEl).data('validEmailStatus', 1);
				},
				error: function( msg ) {
					passRow.addClass('hidden');
					$(this).closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(emailEl).data('validEmailStatus', 0);
				}
			});
			return false;
		},
		ajaxPhone: function( value, options, a ) {
			// email_statuses: 
			// 0 - not_checked / checking / error
			// 1 - checked - email_not_exists
			// 2 - checked - email_exists
			// 3 - logined
			// 4 - process
			var status = $(this).data('validPhoneStatus');
			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( lastVal != $(this).val() ) {
				$(this).data('lastVal', $(this).val());
				status = 0;
			}
			var phoneEl = this;
			if( $(this).closest('form').hasClass('on-change-validate-form') && ( parseInt(this.val().replace(/\D+/g,"")) == this.data('old') || status == 1) ) {
				return true;
			}
			if( status == 1 ) {
				return true;
			}
			var passEl = $(this).closest('form').find('input[name=password]');
			var passRow = passEl.closest('.j-value');
			var pass_btn = $('.pass-btn', passRow);
			if( !pass_btn.data('login-binded-phone') ) {
				pass_btn.data('login-binded-phone', true);
				pass_btn.on('click', function() {
					customer.login(
						{
							customerInfo: {
								pass: passEl.val(),
								email: phoneEl.val(),
								options: {'email_or_phone':true},
							},
							error: function(msg) {
								phoneEl.data('validPhoneStatus', 0);
								passRow.find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>').addClass('not-empty');
							},
							success: function(msg) {
								phoneEl.data('validPhoneStatus', 3);
							}
						}
					);
				});
			}
			customer.checkPhone({
				phone: value,
				phone_exists: function( msg ) {
					passRow.removeClass('hidden');
					passRow.find('.form-control__placeholder').text('������ � ��������');
					$(phoneEl).data('validPhoneStatus', 2);
					var moreText = '';
					if ( phoneEl.hasClass('fastOrderPhone') ) {
						moreText = ' <a class="toLoginTab greydotted">����� � ������</a>';
					}
					if ( phoneEl.hasClass('registerPhone') ) {
						moreText = ' <a class="js-login greydotted">����� � ���� �������</a>';
					}
					$(phoneEl).closest('.j-value').find('.validation-description').html('<div class="error">��� ������� ��� ���������������' + moreText + '</div>');
				},
				phone_not_exists: function( msg ) {
					if( $(this).closest('form').hasClass('on-change-validate-form') ){
						phoneEl.data('validPhoneStatus', 3);
						return true;
					}
					if( $(phoneEl).closest('form').attr('name') == 'create_account' ) {
						$(phoneEl).closest('form').submit();
					}
					passRow.addClass('hidden');
					$(phoneEl).data('validPhoneStatus', 1);
				},
				error: function( msg ) {
					passRow.addClass('hidden');
					$(this).closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(phoneEl).data('validPhoneStatus', 0);
				}
			});
			return false;
		},
		ajaxPhoneAndPassCode: function( value, options ) {
			var status = $(this).data('validPhoneStatus');

			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( typeof lastVal == 'string' ) {
				lastVal = parseInt(lastVal.replace(/\D+/g,""));
			}
			var parsedVal = parseInt($(this).val().replace(/\D+/g,""));
			if( lastVal != parsedVal ) {
				$(this).data('lastVal', parsedVal);
				status = 0;
			}
			var phoneEl = this;
			if( $(this).closest('form').hasClass('on-change-validate-form') && ( parseInt(this.val().replace(/\D+/g,"")) == this.data('old') || status == 1) ) {
				return true;
			}
			if( status == 1 ) {
				return true;
			}
			if( customer.isLogin() && customer.info.phone == parsedVal ) {
				return true;
			}
			var passEl = $(this).closest('form').find('input[name=passCode]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			$(phoneEl).removeClass('exists');
			customer.checkPhoneAndSendPassCode({
				phone: value,
				phone_exists: function( msg ) {
					if( msg.phoneThisLogin == 1 ) {
						phoneEl.data('validPhoneStatus', 1);
						phoneEl.trigger('change');
						return true;
					}
					passRow.removeClass('d-none');
					$(phoneEl).data('validPhoneStatus', 2);
					var moreText = '';
					if ( phoneEl.hasClass('fastOrderPhone') ) {
						moreText = ' <a class="toLoginTab dotted">����� � ������</a>';
					}
					if ( phoneEl.hasClass('registerPhone') ) {
						moreText = ' <a class="js-login dotted">����� � ���� �������</a>';
					}
					$(phoneEl).addClass('exists').closest('.js-row').find('.validation-description').html('<div class="exists">' + msg.err_mes + '</div>');
				},
				phone_not_exists: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
					}
					$(phoneEl).data('validPhoneStatus', 1);
					$(phoneEl).trigger('change');
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
					}
					$(phoneEl).closest('.js-row').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(phoneEl).data('validPhoneStatus', 0);
				}
			});
		},
		dateRu: function( value, options ) {
			if( !/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(value) ) {
				return false;
			} else {
				var arrD = value.split(".");
				arrD[1] -= 1;
				var d = new Date(arrD[2], arrD[1], arrD[0]);
				if ((d.getFullYear() == arrD[2]) && (d.getMonth() == arrD[1]) && (d.getDate() == arrD[0])) {
					return true;
				} else {
					return false;
				}
			}
		},
		'autocomplete-city': function( value, option ) {
			if( $(this).is(':disabled') ) {
				return true;
			}
			if( value.length == 0 || value == 0 ) {
				$(this).closest('.for-autocomplete').parent().selectCity('showError', '�� ����� �����');
				return false;
			} else {
				return true;
			}
		}
	}
};
if ( typeof $.validateSetup === 'function' )
{
	$.validateSetup(defaultValidateSetup);
}
var default_ajax_form_options = {
	dataType: 'json',
	timeout: 15000,
	beforeSubmit: function(formData, jqForm, options, form) {
		if( jqForm.data('zone_id_check') == 1 && array_search( formData, 'zone_id' ).value == 0 ) {
			return false;
		}
		if( jqForm.data('disabled') == 1 ) {
			return false;
		}
		jqForm.addClass('form-loading');
		formData.push({name: 'ajax', value: 3});
	},
	success: function(responseText, statusText, additParam, form) {
		form.removeClass('form-loading');
		if( responseText.data ) {
			$.each( responseText.data, function( selector, data ) {
				el = $( selector );
				el.html( data );
				if( $('form.validate', el) ) {
					$('form.validate', el).validate();
				}
				$('.datepicker', el).datepicker();
				$('.default-validate-form', el).each(function() {
					$(this).validate({
						onChange : $(this).data('validateOnChange') != undefined
					});
				});
				if( $('.on-change-validate-form', el).length ) {
					$('.on-change-validate-form', el).validate({onChange : true,});
				}
				$('.default-ajax-form', el).ajaxForm( default_ajax_form_options );
				$('.phone-11-dig', el).phoneMask();
				var checkboxes = $('input:checkbox', el);
				if( checkboxes.length ) {
					checkboxes.checkbox();
				}
				if( $('#delivery-shops .j-info-shop-map', el).length ) {
					initInfoShops();
				}
				$('.tooltip', el).tooltip_init();
				$('.tabs-body').tabs();
				$(document).on('click', '.btn-see-map', function( e, delivery ) {
					e.preventDefault();
					var form = $(this).closest('form');
					$('input[name=filtrDelivery]', form).val( delivery == undefined ? '' : delivery );
					var tmp_options = form.serializeArray();
					var options = {};
					for (var i = 0; i < tmp_options.length; i++) {
						options[ tmp_options[i].name ] = tmp_options[i].value;
					}
					$('#see-map').trigger('click');
					ymaps.ready(function() {
						$('#map-popup').shopsMap( options );
					});
				});
				$(".delivery-text .tab").click(function(){
					$(".delivery-text .tab").removeClass("active").eq($(this).index()).addClass("active");
					$(".delivery-text .tab-item").hide().eq($(this).index()).fadeIn()
				}).eq(0).addClass("active");
				init_sc_autocomplete( el );
				// el.find('.fancybox').fancybox();
				if( $.inArray('alignPopup', responseText.options) >= 0 ) {
					var popup = el.closest('.popup');
					if( popup.length ) {
						popup.css({
							'top': (popup.outerHeight() - $(window).height() >= 20 ? 0 : (($(window).height()-popup.outerHeight())/2) - 10 ),
							// 'left': (($(window).width() - popup.outerWidth())/2) + $(window).scrollLeft()
						});
					}
				}
			});
		}
	}
};

function fixQty(val, minVal, maxVal, diff) {
	val = +val;
	val = parseInt(val, 10);
	if (isNaN(val)) {
		val = 0;
	}
	val += diff;
	if (val > maxVal) {
		val = maxVal;
	}
	if (val < minVal) {
		val = minVal;
	}
	return val;
}

$.widget("ui.tooltip", $.ui.tooltip, {
	options: {
		content: function () {
			return $(this).prop('title');
		},
		open: function( event, ui ) {
			ui.tooltip.addClass($(this).data('tooltip-class'));
		}
	}
});

$.widget("some.selectCity", $.some.selectCity, {
	options: {
		textManualInput: '������ �������',
		textChooseFromList: '�������� �� ������',
	},
	_initAutocomplete: function() {
		this._on(this.autocompleteInput, {
			focus: function(e) {
				if( (pos = e.target.value.indexOf('(')) > 0 ) {
					e.target.value = e.target.value.substr(0, pos).trim();
				}
			}
		});
		var autocompleteOptions = {
			minLength: 0,
			delay: 100,
			source: $.proxy(this, '_autocompleteSource'),
			select: $.proxy(this, '_onAutocompleteSelect'),
			change: $.proxy(this, '_onAutocompleteChange'),
			open: function (){
				var ul = $(this).autocomplete('widget');
				$(ul).find('.ui-menu-item a').removeClass('ui-corner-all');
				$(ul).find('.ui-menu-error').removeClass('ui-menu-item');
			},
			create: function() {
				$(this).attr('autocomplete', 'new-password');
			}
		};
		if( this.appendTo.length ) {
			autocompleteOptions.appendTo = this.appendTo;
		}
		var widgetInst = this.autocompleteInput.removeClass('autocomplete-city').autocomplete( autocompleteOptions ).data( "ui-autocomplete" );
		var el = this;
		this.autocompleteInput.on('keydown', function(e) {
			if( e.keyCode == 13 ) { // ������ Enter
				$(this).autocomplete('option','change').call($(this));
			}
		});
		widgetInst._renderMenu = function( ul, items ) {
			var that = this;
			$.each( items, function( index, item ) {
				that._renderItemData( ul, item );
			});
			// $( "<li>" ) .addClass('ui-menu-error') .append( "<a href='#' class='manual_cityzone bluedotted'>" + that.options.textManualInput + "</a>" ) .appendTo( ul );
		};
		widgetInst._renderItem = function( ul, item ) {
			return $( "<li>" )
				.append( "<a"+(item.orders_count>0?' class="count_1"':'')+"><span class='cityname' data-zone_id='"+item.zone_id+"'>" + item.city + "</span><br><span style='font-size:10px'>" + item.zone_name + "</span></a>" )
				.appendTo( ul );
		};
	}
});

$.widget("map.shopsMap", $.map.shopsMap, {
	options: {
		texts: {
			freeCost: '���������',
			chooseMetro: '�������� �����',
			textRadius1km: '��� � ������� 1 ��',
			textRadius3km: '��� � ������� 3 ��',
			textRadius10km: '��� � ������� 10 ��',
			textRadiusmore10km: '���������',
			sortByPrice: '�� ���� ��������',
		},
		iconsPoint: {
			src: '/templates/2xu/public/images/delivery/icon28.png',
			size: [33, 41],
			offset: [-12, -48],
		},
		iconCluster: [
			{
				src: '/templates/2xu/public/images/delivery/krug_ym.png',
				size: [40, 40],
				// ������, ����� ����� �������� �������� � ������� ��������.
				offset: [-20, -20]
			},
			{
				src: '/templates/2xu/public/images/delivery/krug_ym.png',
				size: [50, 50],
				offset: [-25, -25]
			}
		],
		mapTabControl: '#map-control',
	}
});

$.fn.redrawMySelect = function() {};

//ShoppingCart
ShoppingCart.prototype.deleteProductCallback = function( data ) {
	return function( msg, textStatus, jqXHR ) {
		var _this = this;
		shoppingCart.setQuantityNPrice( msg );
		if (typeof data.success == 'function') {
			data.success.call(_this, msg);
		}
		deleteProductEvent(msg);
	}
};

ShoppingCart.prototype.reloadPopupCallback = function() {
	return function( msg, textStatus, jqXHR ) {
		this.setQuantityNPrice( productsAdapter(msg ));
	}
}

ShoppingCart.prototype.addPopupEvents = function(){
	var me = this;

	$('#basket-popup .quantity').each(function(){
		initCounterBlock($(this));
	});
	$('#basket-popup .quantity [name="quantity"]').change(function (){
		me.changeProductCount($(this).data('product-id'), $(this).val());
	});
}

ShoppingCart.prototype.setQuantityNPrice = function( msg ) {
	if (!msg){
		return false;
	}

	var isBasketPage =  'shopping_cart' == window.PROSKTR.content,
			me = this;

	if(isBasketPage){
		redrowBasketBlock( msg );
	}

	renderPopupBlock(msg);

	function renderPopupBlock(msg){
		$('#basket-popup .positions').html($.render['shopping_cart_popup_product'].render(msg));
		me.addPopupEvents();
	}

	function redrowBasketBlock( msg ){
		$('.j-cart-total').text( msg.total_price );
		let choosenShippingCost = 0;
		if ( $('.j-shipping-cost').data('choosenshippingcost') != undefined ) {
			choosenShippingCost = $('.j-shipping-cost').data('choosenshippingcost');
		}
		$('.j-order-total').text( msg.total_price * 1 + choosenShippingCost * 1 );

		$('.j-cart-contents').html($.render[ 'shopping_cart_product' ].render(msg.products, {currencies: window.PROSKTR.currencies}));
		$('.checkout-aside-products').html($.render[ 'shopping_cart_aside_product' ].render(msg.products, {currencies: window.PROSKTR.currencies}));
		
		if ( msg.count_contents > 0 ) {
			$('.not-empty-cart').removeClass('d-none');
			$('.empty-cart').addClass('d-none');
		} else {
			$('.empty-cart').removeClass('d-none');
			$('.not-empty-cart').addClass('d-none');
		}

		cookieObj.setCookie('customerorderCnt',  msg.count_contents );
		$('#form-get-delivery').submit();

		let $otherHolder = $('.j-otherGroups');
		$otherHolder.html('');
		if ( msg.otherWhGroups && msg.otherWhGroups.length != 0 ) {
			$('.j-firstorder').removeClass('hidden');
	
			let i = 2;
			for ( group in msg.otherWhGroups ) {
				let headDiv = document.createElement('div');
				headDiv.className = 'basketItem-head subhead';
				headDiv.innerHTML = i + ' ����� - ������������ ��������';
				$otherHolder.append(headDiv);
				i++;
	
				let groupProductsHtml = $.render['shopping_cart_product'].render(
					msg.otherWhGroups[group],
					{customer: window.PROSKTR.customer, currencies: window.PROSKTR.currencies}
					);
				let entriesDiv = document.createElement('div');
				entriesDiv.className = 'cart_contents';

				let headerBlock = document.createElement('header');
				headerBlock.innerHTML = "<span class='product_carted'>�����</span><span class='input'>����������</span><span class='subtotal'>���������</span>";
				entriesDiv.append(headerBlock);

				let listBlock = document.createElement('div');
				listBlock.className = 'cart_contents';
				listBlock.innerHTML = groupProductsHtml;

				entriesDiv.append(listBlock);
				$otherHolder.append(entriesDiv);

				let groupCount = 0;
				let groupPrice = 0;
				for (let index = 0; index < msg.otherWhGroups[group].length; index++) {
					const element = msg.otherWhGroups[group][index];
					groupCount += element.quantity * 1;
					groupPrice += element.quantity * element.final_price;
				}

				let summaryDiv = document.createElement('div');
				summaryDiv.className = 'total';
				summaryDiv.innerHTML = '<label></label><div><strong>�����: ' + window.PROSKTR.currencies.rightMin + '<span class="j-cart-total">' + (groupPrice * 1) + '</span></strong></div>';
				// summaryDiv.innerHTML = '<span class="j-total-products">' + (groupCount * 1) + ' ' + NumToRu_declination(groupCount, '�����', '������', '�������') + ' �� ����� ' + (groupPrice * 1) + ' ' + window.PROSKTR.currencies.rightMin + '</span>';
				$otherHolder.append(summaryDiv);
			};
		} else {
			$('.j-firstorder').addClass('hidden');
		}
	}
}
ShoppingCart.prototype.maximizePopup = function (){

};
ShoppingCart.prototype.addProductCallback = function( data ) {
	var me = this;
	//showSuccessPopup(data);

	return function(productsList){
		me.setQuantityNPrice(productsList);
		$('.j-open-popup-busket').trigger('click');
		if( productsList.dublicate ) {
		} else {
			addProductEvent(productsList);
		}
	}
	/**
	 * ���������� - �� �������� ����� � �������
	 * @param data
	 */
	function showSuccessPopup(data){
		var quantity = data.product_info.find(function (el) {
					return el.name === "quantity";
				}),
				product_name = data.product_info.find(function (el) {
					return el.name === "product_name";
				}),
				product_img_src = data.product_info.find(function (el) {
					return el.name === "product_img_src";
				});

		$('.j-product-add-success .j-product-add-count').text(quantity.value ? quantity.value : 1);
		$('.j-product-add-success .j-product-add-product-name').text(product_name.value ? product_name.value : '');
		$('.j-product-add-success img').attr('src',product_img_src.value ? product_img_src.value : '');
		$('.j-product-add-success').addClass('active');
		setTimeout(function(){
			$('.j-product-add-success').removeClass('active');
		}, 3000);
	}
}
Listing.prototype.settings.formFiltrs = '.filter-item';
Listing.prototype.settings.formShowResponse = function(responseText, statusText, additParam, jqForm) {
	jqForm.removeClass('loading');
	$('.product-list').removeClass('loading');
	if( $('#show_more').val() == 0 ) {
		$('.product-list').empty();
	}

	var productsHtml = $.render[ 'listing_product' ].render( responseText.productsList, { currencies: window.PROSKTR.currencies } );
	$('.product-list').append(productsHtml);

	$('#show_more').val( 0 );
	window.listing.loading = false;
	if( window.listing.queue ) {
		window.listing.queue = false;
		$('#filtr-form').trigger('change');
	}
	window.listing.setFiltrs( responseText.filtrs );
	$('.catalog-products-count').html( responseText.pagination.allItems + NumToRu_declination(responseText.pagination.allItems, ' �����', ' ������', ' �������') );
	$('.reset-btn').css('visibility', $('.filter-holder>.multiselect-enabled.active').length == 0 ? 'hidden' : 'visible');
	var filtrs_checked = 0;
	$.each(responseText.filtrs, function( filtrId, filtrInfo ) {
		filtrs_checked += filtrInfo.info.checked !== undefined ? +filtrInfo.info.checked : 0;
	});
	if( filtrs_checked > 1 ) {
		$('.no-products').show();
	} else {
		$('.no-products').hide();
	}
	$('.pagination').html( responseText.pagination.html );
	if ( responseText.pagination.isLast ) {
		$('.loadmore').addClass('hidden');
	} else {
		$('.loadmore').removeClass('hidden');
	}
	var count = $('#filtr-form input[type=checkbox]:checked').length;
	$('#filtrs-count-active').text('('+ count + ')').attr('data-count',count);
};
function clearProducts(){
	$('.pagination').html('');
	$('.products_list-main').html(getPreloader());
}
function getPreloader(){
	return '��������';
}

function productsAdapter(productsList){
	if(Array.isArray(productsList.products)){
		productsList.products = productsList.products.map(function(product){
			product.bimage_link = product.image_path;
			return product;
		});
	}else{
		productsList.products = [];
	}
	productsList.productsList = productsList.products;
	return productsList;
}
function productsAdapter(productsList){
	if(Array.isArray(productsList.productsList)){
		productsList.productsList = productsList.productsList.map(function(product){
			product.image_path = product.bimage_link;
			product.attributes = {1:product.id.split('}')[1]};
			return product;
		});
	}else{
		productsList.productsList = [];
	}
	productsList.products = productsList.productsList;
	return productsList;
}