$(function(){
	initBonus();

	function initBonus(){
		$(document).on('change', 'select[name=customers_bonus]', function() {
			var div = $(this).closest('.js-customers-presents');
			div.find('.js-ajax-loader').show();
			$.ajax({
				type: 'POST',
				data: {'choosen_bonus': $(this).val()},
				url: 'ajax_shopping_cart.php?action=set_bonus',
				dataType: 'json',
				context: this,
				success: function(msg) {
					window.location.replace('/shopping_cart.php');
				}
			});
		});
	}
});

init_sc_autocomplete();
$('.default-validate-form').each(function() {
	$(this).validate({
		onChange : $(this).data('validateOnChange') != undefined
	});
});

$('#customerAddressForm').submit(function(){$('input[type="submit"]', this).attr('disabled','disabled')})
	.change(function(){$('input[type="submit"]', this).removeAttr('disabled')});

var ajaxSCFormOptions = {
	dataType: 'json',
	error: function(context, xhr, e, status) {
	},
	uploadProgress: function(event, position, total, percent) {
	},
	beforeSubmit: function(formData, jqForm, options) {
		if( parse_str(options.url).action.indexOf('getCustomerCity') >= 0 ) {
			$('#form-get-login').hide();
		}
		// �������� ��������, ���� �����
		if( parse_str(options.url).action.indexOf('getShipping') >= 0 ) {
			if( formData.length ) {
				if ( window.ga ) {
					ga('ec:setAction','checkout', {
						'step': 3,
						'option': formData[0].value
					});
					ga('send', 'pageview');
				}
				if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
				{
					window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_city');
				}
			}
			$('#accountInfo #acc-change-btn').hide();
			$('#customerAddress, #delivery').empty();
			$('#delivery').addClass('loading');
		}

		if( parse_str(options.url).action.indexOf('getAddressFields')>=0 ) {
			$('#customerAddress').empty();
			$('#customerAddress').addClass('loading');
		}
		if( $('#'+jqForm.attr('id')+'-loading').length ) {
			$('#'+jqForm.attr('id')+'-loading').css('visibility', 'visible');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'visible');
		}
		if( $(jqForm).attr('id') == 'form-get-login' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).next().attr('id') == 'form-get-content' ) {
				$(jqForm).hide().next().show();
			}
			$.each( $('#form-get-delivery').serializeArray(), function(i, val) {formData.push(val); } );
		}
		if( $(jqForm).attr('id') == 'form-get-content' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).prev().attr('id') == 'form-get-login' ) {
				$(jqForm).hide().prev().show();
			}
		}
		formData.push({name: 'dataType', value: options.dataType});
	},
	success: function(responseText, statusText, xhr, $form) {				
		// �������� ��� ����
		if( $form.attr('id') == 'createAccountForm' ) {
			$('[name=email_address]', $form).prop('readonly', true);
			$('[name=password]', $form).closest('.form-row').hide();
			if( responseText.customerInfo != undefined && responseText.customerInfo.get_login_shopping_cart ) {
				var name;
				if ( responseText.customerInfo.name )	{
					name = responseText.customerInfo.name.charAt(0);
				} else {
					name = '';
				}
				$('.menu-right__account').html('<a href="/account.php" ><i class="zmdi zmdi-account" data-w="'+name+'"></i></a>');
			}
			if ( window.ga ) {
				ga('ec:setAction','checkout', {
					'step': 2,
					'option': ''
				});
				ga('send', 'pageview');
			}
			if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
			{
				window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_nameemailtel_filled');
			}
		}
		
		if( $('#'+$form.attr('id')+'-loading').length ) {
			$('#'+$form.attr('id')+'-loading').css('visibility', 'hidden');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'hidden');
		}
		$.each( responseText.data, function( id, data ) {
			var place = $('#' + id);
			if( place.length ) {
				place.html( data );
				place.removeClass('loading');
				// hidePaymentButtons(); // TODO
				if( place.find('.default-validate-form').length ) {
					place.find('.default-validate-form').validate();
				}
				if( place.find('.ajaxSCForm').length ) {
					place.find('.ajaxSCForm').ajaxForm(ajaxSCFormOptions);
				}
				place.find('.tooltip').tooltip_init();
				// if( place.find(':checkbox').length ) {
				// 	place.find(':checkbox').checkbox();
				// } // TODO
				place.find('.phone-11-dig').phoneMask();
				if( $.inArray('setInputFocus', responseText.options)!=-1 ) {
					place.find('input:text:first').focus();
				}
				if( $('select.delivery-fitting', place).length ) {
					$('select.delivery-fitting', place).trigger('change.fitting');
				}
				$('.with-placeholder input', place).each(function() {
					$(this)[ $(this).val().trim().length == 0 ? 'removeClass' : 'addClass' ]('edited');
				});
				if( $('.fitting-descrption-popup-opened').length ) {
					$(document).on('click', '.fitting-descrption-popup-opened', function(e) {
						e.preventDefault();
						$.magnificPopup.open({
							items: {
								src: '#sc-primerka-popup',
								type: 'inline',
								closeBtnInside: true,
							},
						});
					});
					$('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
						e.preventDefault();
						$.magnificPopup.close();
					});
					$('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
						e.preventDefault();
						$.magnificPopup.close();
						$('#order-tab').hide();
						$('#products-tab').show();
						$('html, body').animate({ scrollTop: $('#products-tab').offset().top });
					});
				}
			}
			// BOF _gaq.push
			if( id=='customerAddress' ) {
				toggleDontCall();
				$("#customerAddressForm").submit(function(e) {
					if ( $(this).hasClass('form-valid-error') ) {
						return false;
					}
					$('input[type="submit"]', this).prop('disabled', 'disabled');
					$('input[type="submit"]', this).closest('label').addClass('loading-in-btn');
					$('.order-success-btn', this).removeClass('hidden');
				});
			}
			// EOF _gaq.push
		} );
		if( responseText.customerInfo != undefined ) {
			customer.logined = true;
			customer.info.email = responseText.customerInfo.email;
			customer.info.customer_id = parseInt(responseText.customerInfo.customers_id);
		}
		if( $.inArray('cityFocus', responseText.options)!=-1 ) {
			$('#customerCity .city-tab.active input[name=city]').focus();
		}
		if( $.inArray('delivery_date_for_time_change', responseText.options)!=-1 ) {
			$('select.delivery_date_for_time').delivery_date_for_time_change();
		}
		if( responseText.scrollTo && responseText.scrollTo != '#delivery' && responseText.scrollTo != '#customerCity' && responseText.scrollTo != '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $(responseText.scrollTo).offset().top });
		} else if ( responseText.scrollTo && responseText.scrollTo == '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $('#delivery').offset().top });
		}
		if ( responseText.shipping_cost != undefined ) {
			responseText.shipping_cost = responseText.shipping_cost || '-';
			$('.j-shipping-cost').html( window.PROSKTR.currencies.logo + '' + responseText.shipping_cost ).data('choosenshippingcost', responseText.shipping_cost);
			$('.j-order-total').text( responseText.total_formatted );
		}
		var stepsList = $('.order-steps');
		// if( responseText.orderStep && stepsList.length ) {
		// 	stepsList[ responseText.orderStep == 4 ? 'hide' : 'show' ]();
		// 	var el = $('li', stepsList).eq( responseText.orderStep - 1 );
		// 	el.addClass('active').addClass('already').siblings().removeClass('active');
		// 	el.prevAll().addClass('already');
		// 	el.nextAll().removeClass('already');
		// } // TODO
		init_sc_autocomplete();
		// setTimeout( initSCSuggestion, 1000 );
		initSCSuggestion();
	}
}

function initSCSuggestion() {
	var
		token = "483fd2413718ec3fb98454a0256d84481dd60fa5 ",
		type  = "ADDRESS",
		$street = $("#street-dadata"),
		$house = $("#house-dadata");
	if ( !$street.length || !$house.length ) {
		return false;
	}
	if( $('#city-dadata').length == 0 ) {
		return;
	}

	$street.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "street",
		constraints: {
			locations: {
				city: $('#city-dadata').val(),
			},
		}
	});

	$house.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "house",
		constraints: $street
	});

	setTimeout( function(){
		if ( $("#house-dadata").length && $("#street-dadata").val() != '' ) {
			$("#house-dadata").suggestions().fixData();
		}
	}, 1000);
};
initSCSuggestion();

$('.ajaxSCForm').each(function() {
	$(this).ajaxForm(ajaxSCFormOptions);
});

$('#orderTabset').on('click', '.j-s-sbmt', function(e) {
	e.preventDefault();
	$(this).closest('form').submit();
});

$.fn.delivery_date_for_time_change = function() {
	this.each(function() {
		var delivery_row = $(this).closest('.cureer-block');
		var time_select = delivery_row.find('[name='+$(this).attr('time')+']');
		time_select.empty();
		var arr = window[$(this).attr('time')];
		if ( arr ) {
			for (var i = 0; i < arr.length; i++) {
				if( arr[i][0]==$(this).val() ) {
					var o = new Option( arr[i][1], arr[i][1])
					$(o).html( arr[i][1] );
					time_select.append(o);
				}
			}
		}
	});
}

$('#delivery').on('change', 'select.delivery_date_for_time', function( e ) {
	$(this).delivery_date_for_time_change();
});
$('select.delivery_date_for_time').delivery_date_for_time_change();

$('#delivery').on('click', '.del-product-label', function( e ) {
	e.preventDefault();
	data = {};
	data.products_id = $(this).attr('products_id');
	data.success = function() {
		$('#form-get-delivery').submit();
	}
	shoppingCart.deleteProduct( data );
});

$('#delivery').on('click', '.cureer-block', function( e ) {
	if(
		$(e.target).closest('.selectmenu').length == 0 &&
		$(e.target).closest('label').length == 0 &&
		!$(e.target).is(':radio') &&
		!$(e.target).hasClass('shops_map_call') &&
		!$(e.target).hasClass('fitting-descrption-popup-opened')
		) {
		$(this).find('input:radio').prop('checked', true).trigger('click');
	}
});

$('#delivery').on( 'click', '.shops_map_call', function(e) {
	e.preventDefault();
	if ( $(this).hasClass('disabled') ) return;
	$(this).addClass('disabled');
	var options = $(this).data('map-options');
	$('#see-map').trigger('click');
	var el = $(this);
	ymaps.ready(function() {
		$('#map-popup').shopsMap($.extend(options, {
			pvzChoose: function( properties, coordinates ) {
				$('#map-popup').find('*[data-close]').trigger('click');
				// $('.bg', this).trigger('click');
				$('#pvz_id').val( properties.point_ident );
				$('#pvz_resource').val( properties.delivery );
				$('#pvz__city').val( properties.point_city );
				$('#pvz_iml_date').val( properties.pvz_iml_date );
				// el.text('�������� ����� ������ �� �����');
				var cureer_block = el.closest('.cureer-block');
				// $('.send-value', cureer_block).text( properties.dates.send_format ); // ���� ��������
				$('.box .head.quote-date', cureer_block).text( properties.dates.delivery_from_format ); // ���� ��������
				// $('.col-2 .head', cureer_block).text( properties.dates.delivery_days_forprint ); // ��� ��������

				$('.box .head.quote-price', cureer_block).text( properties.cost == 0 ? '���������' : properties.cost + ' �' ).data('value', properties.cost); // ����
				$('.box .head-on-line', cureer_block).remove();
				if( properties.free_cost == 1 && properties.cost > 0 ) {
					$('.box', cureer_block).append( '<strong class="head-on-line tooltip" title="<b>���� ������ �������� ����� ���� ���������� ��� ������ ������!</b><br />��������� �������� ���������� ����� ������ ������.">��������� <div class="icon"><div class="point-pay_cache_no"></div></div></strong>' ); // ����
					$('.box .head-on-line', cureer_block).tooltip_init();
				}

				if( $.inArray(properties.delivery, options.typesToFitting) != -1 ) {
					$('.fitting-holder', cureer_block).show();
					if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).select('enable');
				} else {
					$('.fitting-holder', cureer_block).hide();
					if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).select('disable');
				}

				$('.error', cureer_block).text('');
				$('input[name=shipping]', cureer_block).prop('checked', true);
				$('select.delivery-fitting', cureer_block).val(properties.fitting).trigger('change');
				$('input[name=shipping]', cureer_block).trigger('click');
				el.removeClass('disabled');
				$('#pvz_text', cureer_block).html('<b>����� ����� ��������� �� ������:</b><br>' + properties.point_city + ', ' + properties.address + ' (���� ' + properties.delivery_name + ')');
			},
		}));
	});
} );

$('#delivery').on('click', 'input[name=shipping]', function( e ) {
	var cureer_block = $(this).closest('.cureer-block');
	$('.delivery-form li.cureer-block').removeClass('active');
	$('.delivery_selects').hide();
	$('.delivery_selects.select_' + $(this).val() ).show();
	$('.change-delivery').myShow();
	if( this.value=='pvz_pvz' ) {
		if( cureer_block.find('input[name=pvz_id]').val().length==0 ) {
			$('#delivery .shops_map_call').trigger('click');
			this.checked = false;
			return false;
		}
	}
	else
	{
		$('#delivery').css('min-height', '');
	}
	$('#preCustomerAddress').show();
	$(this).closest('form').attr('action', 'shopping_cart.php?ajax=1&action=setShipping.getAddressFields.getPayments') // ������ �������, ������ ����� �� ��������:(
	$(this).closest('form').submit();
});

$('#delivery').change(function(e) {
	if( e.target.name=='shipping' || $(this).find('input[name=shipping]:checked').length==0 ) {
	} else {
		$(this).attr('action', 'shopping_cart.php?ajax=1&action=setShipping') // ������ �������, ������ ����� �� ��������:(
		$(this).closest('form').submit();
	}
});

$('#delivery').on('change.fitting', '.delivery-fitting', function( e ) {
	var costEl = $(this).closest('.cureer-block').find('.col-3 .head');
	if( e.namespace == "fitting" ) {
		var cost = parseInt(costEl.data('value')) - parseInt($(this).val());
		costEl.data('value', cost);
	} else {
		var cost = parseInt(costEl.data('value')) + parseInt($(this).val());
		costEl.text(cost == 0 ? '���������' : cost + ' ' + window.PROSKTR.currencies.rightMin);
	}
});

$('#delivery').on('click', '.change-delivery', function( e ) {
	e.preventDefault();
	$(this).myHide();
	$('#delivery .delivery-form .cureer-block').fadeIn();
});

$('#delivery').on('change', '.cureer-block select', function( e ) {
	var radio = $(this).closest('.cureer-block').find('input[name=shipping]');
	if( !radio.prop('checked') ) {
		radio.prop('checked', true).click();
	}
});

$('#accountInfo').on('change', 'select[name=address_id]', function() {
	$(this).closest('form').submit();
});
$('#customerAddress').on('click', '#payments .payment-block .payment-button input:radio', function(e) {
	
	$('#payments .payment-block .payment-button').removeClass('active');
	$('#payments .payment-type').not(this).prop('checked', false);
	$(this).closest('label').addClass('active').show();
	$('input[name=paymentGroup]', $(this).closest('.payment-block')).prop('checked', true).trigger('change');
	toggleDontCall();
	$('.change-payment').myShow();
	var thisPaymentLi = $(this).closest('li');
	var otherPaymentsLi = $('.pay-btns>li', $(this).closest('.payment-list')).not( thisPaymentLi );
	otherPaymentsLi.fadeOut();
	$('#payments .payment-list .more-payments').fadeOut();

	var table = $('#order-total-block table');
	if( $(this).attr('name') == 'paymentonLine' ) {
		var subtotal = table.data('subtotalOnline');
		var total = table.data('totalOnline');
		var shipping = table.data('shippingOnline');
		var shippingTip = shipping == '���������' ? '��� ������ ������' : '' ;
		var discount = table.data('discount');
		var subtotalTip = discount > 0 ? '� ������ ' + discount + '% (' + table.data('discountVal') + ') ������ ��� ������ ������' : '' ;
	} else {
		var subtotal = table.data('subtotal');
		var total = table.data('total');
		var shipping = table.data('shipping');
		var subtotalTip = '';
		var shippingTip = table.data('shippingOnline') == '���������' ? '��������� ��� ������ ������' : '' ;
	}
	$('.j-m-subtotal .j-m-value', table).text(subtotal);
	$('.j-m-shipping .j-m-value', table).text(shipping);
	$('.j-m-total .j-m-value', table).text(total);
	$('.j-m-subtotal .j-m-tip', table).text(subtotalTip);
	$('.j-m-shipping .j-m-tip', table).text(shippingTip);
});

$('#customerAddress').on('change', '#payments input[name=paymentGroup]', function(e) {
	$(this).closest('.payment-block').addClass('active').closest('.row').siblings('.row').find('.payment-block').removeClass('active');
});

$('#customerAddress').on('click', '.change-payment', function(e) {
	e.preventDefault();
	$('#payments .pay-btns>li').fadeIn();
	$('#payments .payment-list .more-payments').fadeIn();
	$( this ).myHide();
});

function toggleDontCall() {
	var checkedPaymentEl = $('#payments .payment-block .payment-button input:radio:checked');
	var checkedPayment = $(checkedPaymentEl).val();
	if ( checkedPaymentEl.length && checkedPayment != 'cod' ) {
		$('.dontCallLabel').css('display', 'block');
		$('.dontCallLabel input').prop('checked', true).closest('label').addClass('active');
	} else if ( $('input[name=dontCall]').data('showdontcall') == 1 ) {
		$('.dontCallLabel').css('display', 'block');
	} else {
		$('.dontCallLabel').css('display', 'none');
		$('.dontCallLabel input').prop('checked', false).closest('label').removeClass('active');
	}
}

$(document).on('click', '.j-reset-shipping-and-payment', function(e) {
	e.preventDefault();
	$('.change-payment').trigger('click');
	$('.change-delivery').trigger('click');
	$('#payments input:radio:checked').prop('checked', false);
	$('#delivery input:radio:checked').prop('checked', false).closest('.cureer-block').removeClass('active');
	$('#delivery .delivery_selects').hide();
	$('#preCustomerAddress, #customerAddress').hide();
});

$(document).on('click', '.cart-product .del', function(e){
	e.preventDefault();
	data = {};
	data.products_id = $(this).closest('form').find('[name="products_id[]"]').val();
	shoppingCart.deleteProduct( data );
});

function initNumberInput(){
	$(document).on('click', '.quantity a', function(e) {
		e.preventDefault();
		var wrapper = $(this).closest('.quantity');
		var input = $('input.currCount', wrapper);
		var currentQty = parseInt(input.val(), 10);
		if ($(this).hasClass('decrement')) {
			var val = fixQty(input.val(), 1, input.data('max'), -1);
		} else {
			var val = fixQty(input.val(), 1, input.data('max'), 1);
		}
		if (val != currentQty) {
			input.val(val);
			input.trigger('change');
		}
	});	
	$(document).on('change', '.cart_contents .quantity input.currCount', function() {
		var currentQty = parseInt( $(this).val(), 10 );
		var qty = fixQty( $(this).val(), 1, $(this).data('max'), 0 );
		if( currentQty != qty ) {
			$(this).val( qty );
		}

		$(this).closest('form').find("input.currCount").val( qty );
		var data = $(this).closest('form').serializeArray();
		data.push({name: 'fromPage', value: window.PROSKTR.content});

		$.ajax({
			url: 'ajax_shopping_cart.php?action=update_product_new',
			type: 'POST',
			dataType: 'json',
			context: this,
			data: data,
			success: function(responseText)  { // ����� ��������� ������
				shoppingCart.setQuantityNPrice( responseText );
			},
		});
	});
	$(document).on('submit', '.cart_contents .count-form', function(e){
		e.preventDefault();
		var $input = $('.quantity input.currCount', this);
		var currentQty = parseInt( $input.val(), 10 );
		var qty = fixQty( $input.val(), 1, $input.data('max'), 0 );
		if( currentQty != qty ) {
			$input.val( qty );
		}

		$(this).find("input.currCount").val( qty );
		var data = $(this).serializeArray();
		data.push({name: 'fromPage', value: window.PROSKTR.content});

		$.ajax({
			url: 'ajax_shopping_cart.php?action=update_product_new',
			type: 'POST',
			dataType: 'json',
			context: this,
			data: data,
			success: function(responseText)  { // ����� ��������� ������
				shoppingCart.setQuantityNPrice( responseText );
			},
		});
	});
}

initNumberInput();

customer.setCallback('onLogin', function() {
	$('.tabset.order input[name=email_address]').val( this.info.email ).attr('status', 3).prop('disabled', true);
	$('#form-set-address-book').submit();
	$('.tabset.order input[name=password]').closest('.row').addClass('hidden');
	if( $('.login-form').length ) {
		$('#form-get-customer-city').submit();
	}
	$('#form-get-login').hide();
	if( $('form#createAccountForm').length ) {
		var form = $('form#createAccountForm');
		if( !$('input[name=1click_order_submit]', form).is(':checked') ) {
			if( $('[name=phone]', form).val().length == 0 ) $('[name=phone]', form).val(this.info.phone);
			if( $('[name=firstname]', form).val().length == 0 ) $('[name=firstname]', form).val(this.info.name + ' ' + this.info.lastname);
			$('form#createAccountForm').submit();
		}
	}
	if( this.logined == true && $('span#createAccountForm').length ){
		$('span#createAccountForm').html('');
	}
	if( $('#accountInfoLegal').is(':visible') ) {
		window.location.reload();
	}
});

$('#accountInfo').on('change', '[name=1click_order_submit]', function(){
	var btn = $(this).closest('form').find('.sc-submit');
	var altValue = btn.data('altValue');
	btn.data('altValue', btn.text());
	btn.text(altValue);

	if( this.checked ) {
		$('form#createAccountForm').ajaxFormUnbind();
	} else {
		$('form#createAccountForm').ajaxForm(ajaxSCFormOptions);
	}
});

$(document).on( 'click', '.toOrder', function(){
	var form = $('#createAccountForm');
	$('#orderTabset').show();
	history.pushState([], '', '/shopping_cart.php?goto=checkout');
	$('.cartList').hide();
	$(window).scrollTop( 0 );
	$('input[name=firstname]', form).focus();
});

$('#createAccountForm input[name=passCode]').on('keyup', function(){
	var val = $(this).val();
	if ( val.length == 4 ) {
		customer.loginByPassCode({
			customerInfo: {
				phone: $('#createAccountForm input[name=phone]').val(),
				passCode: $('#createAccountForm input[name=passCode]').val(),
				clearCart: 1,
			},
			error: function(msg) {
				$('#createAccountForm input[name=phone]').data('validPhoneStatus', 0);
				$('#createAccountForm input[name=passCode]')
					.closest('.row')
					.find('.validation-description')
					.html('<div class="error">' + msg.err_mes + '</div>')
					.addClass('not-empty');
			},
			success: function(msg) {
				$('#createAccountForm input[name=phone]').data('validPhoneStatus', 3);
				if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
					window.location.reload();
				}
			}
		});
	}
});

$('#createAccountForm input[name=passCode]').on('change', function(){
	var val = $(this).val();
	if ( val.length > 0 && val.length != 4 ) {
		$('#createAccountForm input[name=passCode]')
			.closest('.row')
			.find('.validation-description')
			.html('<div class="error">� ���������� ���� ������ ���� 4 �����</div>')
			.addClass('not-empty');
	}
});

$('#createAccountForm .sendNewPassCode').on('click', function(e){
	e.preventDefault();
	var $form = $('#createAccountForm');
	if ( $('input[name=phone]', $form).val() != '' ) {
		$.ajax({
			url: '/ajax_customer.php?action=resendNamePassCode&ajax=1',
			type: 'POST',
			data: $form.serializeArray(),
			dataType: 'json',
			context: this,
			success: function( msg ) {
				$('.hint', $form).html(msg.err_mes);
			}
		});
	}
});